<template>
  <div>
    <div class="container mb-0 mt-3 d-flex">
      <div :class="title.col" v-for="title in colTitle" :key="title.name">
        <h2 class="title_h2 mb-0">{{ title.name }}</h2>
      </div>
    </div>
    <div
      class="containerLink mb-3 mt-3 d-flex justify-content-between align-items-center pl-3 pr-3"
      v-for="item in Files"
      :key="item.id"
    >
      <div class="d-flex" id="itemImg">
        <div class="div_col">
          <img
            :class="item.hashName ? 'mr-1' : ''"
            :src="require(`@/assets/image/iconesUpload/youtube.png`)"
            alt="icones"
            v-if="item.link"
          />
          <img
            :src="
              require(`@/assets/image/iconesUpload/${extensaoString(
                item.hashName
              )}.svg`)
            "
            alt="icones"
            v-if="item.hashName"
          />
        </div>
      </div>
      <div class="div_col col-md-5 p-0" id="itemName">
        <div
          class="space rightBorde"
          data-toggle="tooltip"
          data-placement="right"
          :title="item.name"
        >
          {{ item.name }}
        </div>
      </div>
      <!-- <div
        class="div_col col-md-2"
        :class="item.lastModificationTime ? '' : 'pl-5'"
      >
        <div
          class="space rightBorde"
          data-toggle="tooltip"
          data-placement="right"
          :title="
            item.lastModificationTime
              ? getDate(item.lastModificationTime)
              : '...'
          "
        >
          {{ getDate(item.lastModificationTime) || "..." }}
        </div>
      </div> -->
      <div class="col p-0 text-center" id="fileSize">
        <div
          class="space rightBorde"
          data-toggle="tooltip"
          data-placement="right"
          :title="item.fileSize ? formatBytes(item.fileSize) : '...'"
        >
          {{ formatBytes(item.fileSize) }}
        </div>
      </div>
      <div class="col-md-4 d-flex align-items-center divActions">
        <button
          v-if="item.link != ''"
          class="btnLink d-flex align-items-center justify-content-between"
          @click="$emit('linkVideo', item)"
        >
          <img src="@/assets/image/iconesUpload/iconVideo.png" alt="icones" />
          video youtube
        </button>

        <button
          class="btnEdit m-2"
          @click="$emit('download', item, false)"
          v-if="item.hashName != ''"
        >
          <img src="@/assets/image/iconesUpload/download.svg" alt="download" />
        </button>

        <button
          class="btnEdit m-2"
          v-if="item.hashName != ''"
          @click="$emit('download', item, true)"
        >
          <img src="@/assets/image/iconesUpload/eye.svg" alt="eye" />
        </button>

        <button
          class="btnEdit m-2"
          @click="$emit('editFile', item)"
          v-if="profile"
        >
          <img src="@/assets/image/iconesUpload/edit.svg" alt="edit" />
        </button>

        <button
          class="btnEdit delete m-2"
          @click="$emit('deleteFile', item)"
          v-if="profile"
        >
          <img src="@/assets/image/iconesUpload/delete.svg" alt="delete" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["Files"],
  data() {
    return {
      colTitle: [
        {
          name: "Nome",
          col: "col-6"
        },
        // {
        //   name: "Modificado em",
        //   col: "col-2 ml-4"
        // },
        {
          name: "Tamanho",
          col: "col-2 text-center"
        }
      ]
    };
  },
  computed: {
    profile() {
      return this.$store.getters.getUser.profile === 1;
    }
  },
  mounted() {
    $('[data-toggle="tooltip"]').tooltip();
    this.Files.forEach(t => {
      console.log("lenght", t.hashName == "");
      console.log("item", t.hashName);
    });
  },
  methods: {
    getDate(val) {
      if (!val) return;
      var data = new Date(val);
      return `${data.getDate()}/${data.getMonth() + 1}/${data.getFullYear()}`;
    }
  }
};
</script>

<style scoped>
.div_col {
  width: 60px;
}

.containerLink {
  border: 1px solid #98c4e6;
  box-sizing: border-box;
  background: #3f88c1;
  border-radius: 12px;
  height: 50px;
  color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  width: auto;
  padding-right: 10px;
}

.containerLink:hover {
  background: #3f89c1bb;
}

.containerLink a {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
}

.btnEdit {
  background: none;
  border: none;
  height: 30px;
  width: 40px;
  border-radius: 5px;
  transition: 0.2s ease-in-out;
  outline: none;
}

.btnEdit:hover {
  border: 1px solid #2474b2;
  background: rgba(43, 145, 223, 0.788);
}

.delete:hover {
  background: rgba(255, 0, 0, 0.623);
}

.title_h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #ffffff;
}

.divActions {
  width: 200px;
  overflow: auto;
  margin-right: 5px;
}

.space {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  border-right: 1px solid #98c4e6;
}

.borders {
  border-right: 1px solid #98c4e6;
}

.btnLink {
  border: none;
  color: #f38235;
  background: #fff;
  font-size: 12px;
  height: 30px;
  border-radius: 5px;
  font-weight: 800;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #acdbff;
}

::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
@media screen and (max-width:767px){
  #fileSize{
    display: none!important;
  }
  #itemImg{
    width: 22%;
  }
  #itemName{
    width: 45%;
  }
  .divActions{
    padding: 0;
  }
}
</style>
